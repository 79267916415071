
export default {
    components: {

    },
    data() {
        return {
            activeName: 'one',
            // elmentList: [
            //     {
            //         pcElementId: 350,
            //         phoneElementId: 375,
            //         descList: [
            //             {
            //                 descName: '七腾机器人',
            //                 sort: 1
            //             }, {
            //                 descName: '成为全球机器人领先企业',
            //                 sort: 2
            //             }
            //             , {
            //                 descName: '公司介绍',
            //                 sort: 3
            //             }, {
            //                 descName: '七机器人有限公司(以下简称"七机器人")成立于2010年，是一家集特种器人设计、研发生产、售、服务为一体的局新技术企业，在应急安全领域处于头部她位七腾机器人旨在通过自研特种机器人服务于全球应急安全终端市场，将科技创新融入产品和服务，形成多业态融合、多场景覆盖的格局，为社会安全保驾护航。腾机器人重承“让社会重智能”的企业愿蛋，以“成为全球机器人领先企业”为使命，聚焦石油、化工、电气等行业应用，拥有8项核心技术、16项势功能、468种算法药量，形成以硬件为基础、软件为引警、算法为核心的完整AI技术链。',
            //                 sort: 4
            //             }, {
            //                 descName: '针对各类高温高压、有毒有害、易燃易爆、危险物生产、仓储等环境，产品涵盖防爆化工轮式巡检机器人防爆化工四足机器人，防爆化工挂轨巡检机器人等为中石油、中石化、中海油、中化集团、巴斯夫等海内外知名企业提供产品服务和解决方案，已开拓欧洲、非洲、东南亚、西北亚等海外市场。截至目前，七腾机器人已为全球40多个国家及地区的上千家企业有效解决了六千多个痛点难点问题。',
            //                 sort: 5
            //             }
            //             , {
            //                 descName: '七腾机器人总部及全球研发中心位于中国第三个国家级新区-重庆两江新区设立北京全球战略中心和深圳供应链中心，分别在重庆江西设立机器人生产基地，并建立全球营销和售后服务网点。',
            //                 sort: 6
            //             }, {
            //                 descName: '七机器人注重自主创新，引进海内外行业技术领军人才组建百余人研发队，建有四足仿生机器人实验室、智能巡检机器人实验室、抗射机器人实验室。企业获得百余项专利技术成果，荣获国家知识产权优势企业、专精特新“小巨人”企业、重庆市新型研发机构、智能巡检机器人综合实力竞争企业等国家级、省部级、行业级企业荣营。未来，七腾机器人将聚焦客户需求，以技术创新为引警，拓展行业应用场景，促进服务满意度再升级，持续布局国际市场，致力发展成为具有全球竞争力的机器人头部企业。',
            //                 sort: 7
            //             }],
            //         type: 'image',
            //         sort: 1
            //     },
            //     // 发展历程
            //     {
            //         pcElementId: 351,
            //         phoneElementId: 376,
            //         descList: [
            //             {
            //                 descName: '发展历程',
            //                 sort: 1
            //             }, {
            //                 descName: '七腾近期规划:七腾机器人将发挥核心技术优势，优化产品系列及完善产品线，推动机器人产业链多元化，提升性能与质量，深化行业应用，向更加人性化智能化高端化机器人迈进，持续推进全球化战略布局。',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 2
            //     },
            //     {
            //         pcElementId: 369,
            //         phoneElementId: 377,
            //         descList: [
            //             {
            //                 descName: '公司成立',
            //                 sort: 1
            //             }, {
            //                 descName: '初创七腾团队，CTS1.0软件问世并广泛应用，2010年，重庆七腾科技(现更名为七腾机器人)成立，摸索出一条以软件定制开发为主要业务的发展道路，迈进了软件定制服务领域的门槛',
            //                 sort: 2
            //             }, {
            //                 descName: '2008~2010年',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 3
            //     },
            //     {
            //         pcElementId: 370,
            //         phoneElementId: 378,
            //         descList: [
            //             {
            //                 descName: '创新升级',
            //                 sort: 1
            //             }, {
            //                 descName: '七腾机器人在曲折中前进，认识到掌握核心技术优势才是公司走向成熟的必经之路，七腾机器人开始第一次转型升级，向客户提供行业信息化和智能化系统整体解决方案',
            //                 sort: 2
            //             }, {
            //                 descName: '2015~2017年',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 4
            //     },
            //     {
            //         pcElementId: 371,
            //         phoneElementId: 379,
            //         descList: [
            //             {
            //                 descName: '企业转型',
            //                 sort: 1
            //             }, {
            //                 descName: '重塑战略定位，向智能装备产品方向转变，2017年开始探索智能机器人市场风口，正式开启特种机器人服务应急安全领域的新篇章',
            //                 sort: 2
            //             }, {
            //                 descName: '2011~2014年',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 5
            //     },
            //     {
            //         pcElementId: 372,
            //         phoneElementId: 380,
            //         descList: [
            //             {
            //                 descName: '持续发展',
            //                 sort: 1
            //             }, {
            //                 descName: '七腾创新能力持续升级，重庆及江西两大生产基地正式投产，消防、电力、石化智能机器人相继问世并实现量产化，同期防爆化工轮式巡检机器人综合实力行业领先，从而实现特种机器人设计、研发、生产、销售、服务一体化建设',
            //                 sort: 2
            //             }, {
            //                 descName: '2018~2020年',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 6
            //     },
            //     {
            //         pcElementId: 373,
            //         phoneElementId: 373,
            //         descList: [
            //             {
            //                 descName: '行业前列',
            //                 sort: 1
            //             }, {
            //                 descName: '七腾机器人实现“质”的飞跃:设计研发能力、创新技术成果、企业综合实力跻身行业头部位置，球营销及售后服务网点全面建成，七腾机器人产品向轻量化、仿生化、四足化转变，应用场景向森林、无人区、矿区等复杂、非铺装路况等拓展，应用行业向石化、安防、电力、煤炭、钢铁、环保等领域深耕',
            //                 sort: 2
            //             }, {
            //                 descName: '2021~至今',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 7
            //     },
            //     // 荣誉资质
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '荣誉资质',
            //                 sort: 1
            //             }, {
            //                 descName: '截至目前，七腾机器人累计获得国家级荣誉奖项5项、省部级荣誉奖项11项、行业荣誉奖项、资质认证超35项',
            //                 sort: 2
            //             }
            //         ],
            //         type: 'image',
            //         sort: 8
            //     },
            //     {
            //         pcElementId: 352,
            //         phoneElementId: 352,
            //         descList: [
            //             {
            //                 descName: '高新技术企业',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 9
            //     },
            //     {
            //         pcElementId: 353,
            //         phoneElementId: 353,
            //         descList: [
            //             {
            //                 descName: '国家知识产权优势企业',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 10
            //     },
            //     {
            //         pcElementId: 354,
            //         phoneElementId: 354,
            //         descList: [
            //             {
            //                 descName: '重庆市企业技术中心',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 11
            //     },
            //     {
            //         pcElementId: 355,
            //         phoneElementId: 355,
            //         descList: [
            //             {
            //                 descName: '信用3A',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 12
            //     },
            //     {
            //         pcElementId: 356,
            //         phoneElementId: 356,
            //         descList: [
            //             {
            //                 descName: '优秀案例',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 13
            //     },
            //     {
            //         pcElementId: 357,
            //         phoneElementId: 357,
            //         descList: [
            //             {
            //                 descName: '重庆首台（套）重大技术装备',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 14
            //     },
            //     {
            //         pcElementId: 358,
            //         phoneElementId: 358,
            //         descList: [
            //             {
            //                 descName: '专精特新小巨人',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 15
            //     },
            //     {
            //         pcElementId: 359,
            //         phoneElementId: 359,
            //         descList: [
            //             {
            //                 descName: '综合竞争力企业',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 16
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 17
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 18
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 19
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 20
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 21
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 22
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 23
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 24
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 25
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 26
            //     },

            //     // 专利技术
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '专利技术',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '拥有8项核心技术、16项领先功能、468种算法数量，得了百余项专利技术成果',
            //                 sort: 2
            //             }
            //         ],
            //         type: 'image',
            //         sort: 27
            //     },
            //     {
            //         pcElementId: 360,
            //         phoneElementId: 360,
            //         descList: [
            //             {
            //                 descName: '伺服泵控直驱机器人腿足系统',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 28
            //     },
            //     {
            //         pcElementId: 361,
            //         phoneElementId: 361,
            //         descList: [
            //             {
            //                 descName: '大数据分析系统v1.0',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 29
            //     },
            //     {
            //         pcElementId: 362,
            //         phoneElementId: 362,
            //         descList: [
            //             {
            //                 descName: '防爆通用底盘及轮式巡检机器人',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 30
            //     },
            //     {
            //         pcElementId: 363,
            //         phoneElementId: 363,
            //         descList: [
            //             {
            //                 descName: '外观设计专利',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 31
            //     },
            //     {
            //         pcElementId: 364,
            //         phoneElementId: 364,
            //         descList: [
            //             {
            //                 descName: '巡检管理系统',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 32
            //     },
            //     {
            //         pcElementId: 365,
            //         phoneElementId: 365,
            //         descList: [
            //             {
            //                 descName: '降噪式底盘及巡检机器人',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 33
            //     },
            //     {
            //         pcElementId: 366,
            //         phoneElementId: 366,
            //         descList: [
            //             {
            //                 descName: '腿足机器人',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 34
            //     },
            //     {
            //         pcElementId: 367,
            //         phoneElementId: 367,
            //         descList: [
            //             {
            //                 descName: '液压泵控防爆四足机器人',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 35
            //     },
            //     {
            //         pcElementId: 368,
            //         phoneElementId: 368,
            //         descList: [
            //             {
            //                 descName: '云台扫描式激光可燃气体探测系统V1.0',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 36
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 37
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 38
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 39
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 40
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 41
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 42
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 43
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 44
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 45
            //     },
            //     {
            //         pcElementId: 374,
            //         phoneElementId: 381,
            //         descList: [
            //             {
            //                 descName: '行业地位',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '近年来，七腾机器人坚持”创新驱动、应用牵引、基础提升、融合发展",创新活力竟相进发，科技成果不断涌现市场竞争力稳步提升，产品市场占有率位居全国前列,成为应急安全领域头部企业。',
            //                 sort: 2
            //             },
            //             {
            //                 descName: '2021年，七腾机器人生产的防爆化]能巡检机器人在行业内处于领先地位。',
            //                 sort: 3
            //             },
            //             {
            //                 descName: '2022年4月，七腾机器人荣获由维科网(OFweek)颁发的“2021年度智能巡检机器人综合竞争力企业奖。',
            //                 sort: 4
            //             },
            //             {
            //                 descName: '2022年6月维科网(OFweek)发布《2022中国智能巡检机器人行业发展研究白皮书》，七腾机器人在“智能巡检机器人行业头部厂商”榜单上排名第一。',
            //                 sort: 5
            //             },
            //             {
            //                 descName: '2023年4月七腾机器人获得维科网(OFweek) 2022中国机器人行业年度优秀应用案例奖。',
            //                 sort: 6
            //             },
            //         ],
            //         type: 'image',
            //         sort: 46
            //     },
            // ],
            elmentList:[],
            mobileOpenType: 'introduce',
            activeNames: []
        }
    },
    mounted() {
        // 改变导航栏为黑色
        document.documentElement.style.setProperty('--var-primary-color', '#000000');
        this.$store.dispatch("pageset/getbannerIndex", 1);
        // this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then((res) => {
            this.elmentList =res.data.elmentList
            setTimeout(() => {
            }, 100);
        });
    },
    methods: {
        // 回到顶部
        scollTop() {
            document.documentElement.scrollTop = 0;
        },
        openAbout(type) {
            this.mobileOpenType = type;
            this.activeNames = [];
        },
        changeEMSP(val){
            return val.replace(/ /g, '&emsp;');
        }
    }
}